import { useEffect, useState } from "react";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import type { CurrentUser, NeedsLogin } from "api/types";
import SignInPage from "./SignInPage";
import { Alert, Container, Navbar, Spinner } from "react-bootstrap";
import Image from "next/image";
import Link from "next/link";
import { EnvironmentBadge, Sidebar } from "@bagel-web/components";
import { getIsActive, getNavConfig } from "utils/navConfig";
import { initUserContext } from "@bagel-web/common";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not automatically retry failed queries. that's surprising behaviour, and
      // kind of annoying when developing.
      retry: 0,
    },
  },
});

TimeAgo.addDefaultLocale(en);

function Header({ whoami }: { whoami?: CurrentUser | null }) {
  return (
    <Navbar className="bg-primary px-3" id="header">
      <Navbar.Brand>
        <Link href="/" className="navbar-brand">
          <Image
            className="me-3"
            src="/static/logo-mark-yellow.svg"
            alt="Artificial Agency Logo"
            width={30}
            height={30}
            priority
            style={{
              verticalAlign: "top",
              filter: "brightness(0.2) grayscale(1)",
            }}
          />
          Admin
        </Link>
        <EnvironmentBadge />
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        {whoami?.username}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default function ClientLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [whoami, setWhoami] = useState<CurrentUser | null>(null);
  const [needsLogin, setNeedsLogin] = useState<NeedsLogin | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const authenticate = async () => {
      let response;
      try {
        response = await fetch("/api/auth/whoami");
        const jsonBody = await response.json();
        const { status } = response;
        if (status === 200) {
          initUserContext(jsonBody.id, null);
          setWhoami(jsonBody);
        } else if (status === 401) {
          setNeedsLogin(jsonBody);
        } else {
          throw new Error(`Unexpected response status ${status}`);
        }
      } catch (e) {
        setError(
          e instanceof Error ? e : new Error("Unable to fetch current user.")
        );
      }
    };

    authenticate();
  }, []);

  if (error) {
    return (
      <Container>
        <Alert variant="warning">Authentication error: {error?.message}</Alert>
      </Container>
    );
  }

  if (!whoami) {
    if (!needsLogin) {
      return (
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      );
    } else if (!whoami) {
      return (
        <>
          <Header whoami={whoami} />
          <SignInPage
            clientId={needsLogin.client_id}
            onSuccess={(whoami) => setWhoami(whoami)}
          />
        </>
      );
    }
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <div className="vh-100 d-flex flex-column align-items-stretch">
          <Header whoami={whoami} />
          <div
            className="d-flex flex-row align-items-stretch"
            style={{ flexGrow: 1, flexShrink: 1, overflow: "hidden" }}
          >
            <Sidebar
              config={getNavConfig()}
              LinkComponent={Link}
              getIsActive={getIsActive}
            />
            <main style={{ flexGrow: 1, overflow: "hidden" }}>{children}</main>
          </div>
        </div>
      </QueryClientProvider>
    );
  }
}
