import { useQuery } from "@tanstack/react-query";
import { Form, Spinner } from "react-bootstrap";

import type { CommonProjectSummary } from "../types/Project";

const projectQueries = {
  all: () => ["projects"],
};

function ProjectSelectOption({ project }: { project: CommonProjectSummary }) {
  return (
    <option key={project.id} value={project.id}>
      {project.name || project.id}
    </option>
  );
}

export function ProjectSelectPresentation({
  projectId,
  onChange,
  projects,
  showOrganization,
}: {
  projectId: string | null;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  projects: Array<CommonProjectSummary>;
  showOrganization?: boolean;
}) {
  const organizations = [
    ...new Set(
      projects.map((project) =>
        "organization" in project ? project.organization : undefined
      )
    ),
  ];
  return (
    <Form.Select value={projectId || ""} onChange={onChange}>
      <option />
      {showOrganization
        ? organizations.map((organization) => {
            return (
              <optgroup label={organization} key={organization}>
                {projects
                  .filter(
                    (project) =>
                      "organization" in project &&
                      project.organization === organization
                  )
                  .map((project) => (
                    <ProjectSelectOption key={project.id} project={project} />
                  ))}
              </optgroup>
            );
          })
        : projects.map((project) => (
            <ProjectSelectOption key={project.id} project={project} />
          ))}
    </Form.Select>
  );
}

export type ProjectSelectProps = {
  value: string | null;
  fetchProjects: () => Promise<{ items: CommonProjectSummary[] }>;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  showOrganization?: boolean;
};

function ProjectSelect({
  value,
  fetchProjects,
  onChange,
  showOrganization = true,
}: ProjectSelectProps) {
  const {
    isPending,
    isError,
    data: projects,
  } = useQuery({
    queryKey: projectQueries.all(),
    queryFn: fetchProjects,
  });

  if (isPending) {
    return <Spinner size="sm" />;
  } else if (isError) {
    return <div>Failed to load projects.</div>;
  }

  return (
    <ProjectSelectPresentation
      projects={projects.items}
      projectId={value}
      onChange={onChange}
      showOrganization={showOrganization}
    />
  );
}

export default ProjectSelect;
