import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Link from "next/link";
import styled from "styled-components";

import {
  BackLink,
  ColorAA,
  DiffView,
  FixedToolbarItem,
  FlexToolbarItem,
  Page,
  Toolbar,
  TooltipProvider,
} from "@bagel-web/components";
import EventStreamHeaderDetail from "./EventStreamHeaderDetail";
import MomentDetailSidebar from "./MomentDetailSidebar";
import { formatTimestamp } from "../util";
import {
  CommonBaseMessage,
  CommonEventStream,
  CommonMomentDetail,
  CommonMomentEvent,
  CommonMomentSummary,
  CommonSessionDetails,
  FactKwargs,
} from "../types/Session";
import {
  BagelForkEventStreamCallback,
  EventStreamSettings,
  FunctionCallResult,
  Source,
} from "../types";
import MomentNavigator from "./MomentNavigator";
import { Spinner } from "react-bootstrap";
import EventStreamSettingsButton from "./EventStreamSettingsButton";
import { useLocalStorage } from "../util/localStorage";

function DisplaySource({ source }: { source: Source }) {
  if (source === "user") {
    return <i className="bi-controller" />;
  } else if (source === "assistant") {
    return <i className="bi-robot" />;
  } else if (source === "system") {
    return <i className="bi-braces" />;
  } else if (source === "function") {
    return <i className="bi-exclamation" />;
  }
  source satisfies never;
}

function DisplayFnResultIcon({
  resultType,
}: {
  resultType: FunctionCallResult;
}) {
  if (resultType === "success") {
    return <i className="bi-check" style={{ color: "green" }} />;
  } else if (resultType === "failure") {
    return <i className="bi-x" style={{ color: "red" }} />;
  } else if (resultType === "warning") {
    return <i className="bi-exclamation" style={{ color: "orange" }} />;
  } else if (resultType === "update") {
    return <></>;
  }
  resultType satisfies never;
}

export function FunctionCall({
  fn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: { name: string; args: Record<string, any> };
}) {
  return (
    <code style={{ whiteSpace: "pre-wrap" }}>
      {fn.name}(
      {Object.keys(fn.args)
        .map((argName) => {
          const prettyValue = JSON.stringify(fn.args[argName], null, 2);
          return `${argName}=${prettyValue}`;
        })
        .join(", ")}
      )
    </code>
  );
}

function Fact({
  kwargs,
  prevKwargs,
  displaySettings,
}: {
  kwargs: FactKwargs;
  prevKwargs?: FactKwargs;
  displaySettings: EventStreamSettings;
}) {
  return (
    <>
      <h6>
        Fact <code>{kwargs.fact_name}</code>
      </h6>
      {displaySettings.showFactDiffs ? (
        <DiffView
          oldValue={prevKwargs ? prevKwargs.content : kwargs.content}
          newValue={kwargs.content}
          showDiffOnly={
            displaySettings.hideUnchagedFactLines &&
            !!prevKwargs &&
            prevKwargs.content !== kwargs.content
          }
        />
      ) : (
        <p
          style={{
            fontFamily: "monospace",
            fontSize: ".875em",
          }}
        >
          {kwargs.content}
        </p>
      )}
    </>
  );
}

const EventTableRow = styled.tr<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 5px solid white;

  > td {
    padding: 5px;
  }
`;

function HighlightableEventRow({
  children,
  fnCallId,
  highlightedFnCallId,
  onHighlightFnCallId,
}: {
  children: ReactNode;
  fnCallId: string | null;
  highlightedFnCallId: string | null;
  onHighlightFnCallId: (fnCallId: string | null) => void;
}) {
  const isHighlighted = highlightedFnCallId && highlightedFnCallId === fnCallId;
  const background = isHighlighted ? "#cde" : ColorAA.lightBeige;

  return (
    <EventTableRow
      $backgroundColor={background}
      onMouseEnter={() => fnCallId && onHighlightFnCallId(fnCallId)}
      onMouseLeave={() => onHighlightFnCallId(null)}
    >
      {children}
    </EventTableRow>
  );
}

export const EventContent = styled.span`
  white-space: pre-wrap;
`;

export const EventBody = styled.span`
  font-family: monospace;

  &,
  input,
  select,
  textarea {
    font-size: 14px !important;
  }
`;

function EventRow({
  event,
  prevEvent,
  highlightedFnCallId,
  onHighlightFnCallId,
  displaySettings,
}: {
  event: CommonMomentEvent;
  prevEvent?: CommonMomentEvent;
  highlightedFnCallId: string | null;
  onHighlightFnCallId: (fnCallId: string | null) => void;
  displaySettings: EventStreamSettings;
}) {
  const message = event.message;

  let content: React.ReactElement | string;
  let fnCallId: string | null = null;
  let resultType: FunctionCallResult | null = null;
  let sender: string | null = null;
  if (
    message.type === "ContentEventMessage" ||
    message.type === "ImportantContentEventMessage"
  ) {
    content = (
      <>
        <EventBody>{message.kwargs.content as string}</EventBody>
        {message.type === "ImportantContentEventMessage" && (
          <h6>
            Importance <code>{message.kwargs.importance}</code>
          </h6>
        )}
      </>
    );
    fnCallId = message.kwargs.function_call?.id || null;
    resultType = message.kwargs.function_call?.type || null;
    sender = message.kwargs.sender;
  } else if (message.type === "FnCallEventMessage") {
    content = <FunctionCall fn={message.kwargs.function_call} />;
    fnCallId = message.kwargs.function_call_id;
    sender = message.kwargs.sender;
  } else if (message.type === "FactEventMessage") {
    content = (
      <Fact
        kwargs={message.kwargs}
        prevKwargs={
          prevEvent?.message.type === "FactEventMessage"
            ? prevEvent.message.kwargs
            : undefined
        }
        displaySettings={displaySettings}
      />
    );
    sender = message.kwargs.sender;
  } else {
    const baseMessage = message as unknown as CommonBaseMessage;
    sender = baseMessage.kwargs.sender;
    content = (
      <EventBody>
        {JSON.stringify({ ...baseMessage.kwargs, sender: undefined }, null, 2)}
      </EventBody>
    );
  }

  return (
    <HighlightableEventRow
      fnCallId={fnCallId}
      highlightedFnCallId={highlightedFnCallId}
      onHighlightFnCallId={onHighlightFnCallId}
    >
      <td align="right" className="pe-2">
        <DisplaySource source={event.message.source} />
      </td>
      <td>{sender === null ? "(no sender)" : sender}</td>
      <td>{resultType && <DisplayFnResultIcon resultType={resultType} />}</td>
      <td colSpan={message.type === "FnCallEventMessage" ? 1 : 2}>
        <EventContent>{content}</EventContent>
      </td>
    </HighlightableEventRow>
  );
}

const MomentLink = styled.a`
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
`;

const MomentTimestamp = styled.span`
  font-style: italic;
  color: ${ColorAA.grey};
  margin: 0px 10px;
`;

function MomentHeader({
  moment,
  onClick,
}: {
  moment: CommonMomentSummary;
  onClick: (momentId: number) => void;
}) {
  return (
    <>
      <TooltipProvider tooltip="Show moment details">
        <MomentLink onClick={() => onClick(moment.id)}>
          Moment {moment.id}
        </MomentLink>
      </TooltipProvider>
      <MomentTimestamp>{formatTimestamp(moment.created)}</MomentTimestamp>
    </>
  );
}

function getPreviousFact(
  event: CommonMomentEvent,
  prevMoment?: CommonMomentSummary
) {
  let prevEvent;
  if (event.message.type === "FactEventMessage") {
    prevEvent = prevMoment?.events?.find(
      (e) =>
        e.message.type === "FactEventMessage" &&
        event.message.type === "FactEventMessage" &&
        e.message.kwargs.fact_name === event.message.kwargs.fact_name
    );
  }
  return prevEvent;
}

const UnchangedFactsLink = styled.a`
  margin-left: 4px;
`;

function DisplayMoment({
  moment,
  prevMoment,
  highlightedFnCallId,
  onHighlightFnCallId,
  onClick,
  displaySettings,
}: {
  moment: CommonMomentSummary;
  prevMoment?: CommonMomentSummary;
  highlightedFnCallId: string | null;
  onHighlightFnCallId: (fnCallId: string | null) => void;
  onClick: (momentId: number) => void;
  displaySettings: EventStreamSettings;
}) {
  const [hideUnchangedFacts, setHideUnchangedFacts] = useState(
    displaySettings.hideUnchangedFacts
  );

  useEffect(() => {
    setHideUnchangedFacts(displaySettings.hideUnchangedFacts);
  }, [displaySettings.hideUnchangedFacts]);

  const prevFactMap = useMemo(() => {
    const prevFacts: Record<string, CommonMomentEvent> = {};
    moment.events.forEach((event) => {
      const prevFact = getPreviousFact(event, prevMoment);
      if (prevFact) {
        prevFacts[event.id] = prevFact;
      }
    });
    return prevFacts;
  }, [moment, prevMoment]);

  const filteredEvents = useMemo(
    () =>
      hideUnchangedFacts
        ? moment.events.filter((event) => {
            const prevMessage = prevFactMap[event.id];
            // Exclude unchanged facts unless they have been revealed
            return (
              event.message.type !== "FactEventMessage" ||
              prevMessage?.message.type !== "FactEventMessage" ||
              event.message.kwargs.content !==
                prevMessage?.message.kwargs.content
            );
          })
        : moment.events,
    [moment, prevMoment, hideUnchangedFacts, prevFactMap]
  );

  const unchangedFactCount = moment.events.length - filteredEvents.length;

  return (
    <>
      <tr>
        <td
          colSpan={4}
          id={`moment-${moment.id}`}
          data-moment-id={moment.id}
          className="moment-summary"
        >
          <MomentHeader moment={moment} onClick={onClick} />
          <UnchangedFactsLink
            href="#"
            onClick={() => setHideUnchangedFacts(false)}
          >
            {unchangedFactCount > 0 &&
              `Show ${unchangedFactCount} unchanged ${unchangedFactCount == 1 ? "Fact" : "Facts"}`}
          </UnchangedFactsLink>
        </td>
      </tr>
      {filteredEvents.map((event) => {
        return (
          <EventRow
            key={event.id}
            event={event}
            prevEvent={prevFactMap[event.id]}
            highlightedFnCallId={highlightedFnCallId}
            onHighlightFnCallId={onHighlightFnCallId}
            displaySettings={displaySettings}
          />
        );
      })}
    </>
  );
}

const EVENT_STREAM_SETTINGS_LOCAL_STORAGE_KEY = "event-stream-settings";

function EventStream({
  sessionId,
  agentId,
  eventStream,
  refetch,
  isPending,
  isRefetching,
  fetchSession,
  fetchEventStreamDetail,
  forkEventStream,
  reverse,
  onSetReverse,
}: {
  sessionId: string;
  agentId: string;
  eventStream?: CommonEventStream;
  refetch: () => void;
  isPending: boolean;
  isRefetching: boolean;
  fetchSession: (sessionId: string) => Promise<CommonSessionDetails>;
  fetchEventStreamDetail: (
    sessionId: string,
    agentId: string,
    momentId: number
  ) => Promise<CommonMomentDetail>;
  forkEventStream: BagelForkEventStreamCallback;
  reverse: boolean;
  onSetReverse: (reverse: boolean) => void;
}) {
  const startOfStream = useRef<null | HTMLDivElement>(null);
  const endOfStream = useRef<null | HTMLDivElement>(null);

  const [detailMomentId, setDetailMomentId] = useState<number | null>(null);

  const handleClickMoment = useCallback(
    (momentId: number) => {
      setDetailMomentId(momentId);
    },
    [setDetailMomentId]
  );

  const [displaySettings, setDisplaySettings] = useState<EventStreamSettings>(
    useLocalStorage<EventStreamSettings>().getItem(
      EVENT_STREAM_SETTINGS_LOCAL_STORAGE_KEY
    ) || {
      showFactDiffs: true,
      hideUnchagedFactLines: true,
      hideUnchangedFacts: true,
    }
  );
  const [highlightedFnCallId, setHighlightedFnCallId] = useState<string | null>(
    null
  );

  return (
    <Page>
      <Page.Header className="shadow">
        <BackLink href={`/sessions/${sessionId}`} LinkComponent={Link}>
          Session
        </BackLink>
        <Toolbar>
          <h2>Event stream</h2>
          <FlexToolbarItem>
            <EventStreamHeaderDetail
              sessionId={sessionId}
              agentId={agentId}
              fetchSession={fetchSession}
            />
          </FlexToolbarItem>
          <FixedToolbarItem>
            <MomentNavigator
              startOfStream={startOfStream.current}
              endOfStream={endOfStream.current}
              moments={eventStream?.moments || []}
              detailMomentId={detailMomentId}
              refetch={refetch}
              isRefetching={isRefetching}
              reverse={reverse}
              onSetReverse={onSetReverse}
            />
          </FixedToolbarItem>
          <FixedToolbarItem>
            <EventStreamSettingsButton
              settings={displaySettings}
              onSettingsChanged={(settings) => {
                useLocalStorage<EventStreamSettings>().setItem(
                  EVENT_STREAM_SETTINGS_LOCAL_STORAGE_KEY,
                  settings
                );
                setDisplaySettings(settings);
              }}
            />
          </FixedToolbarItem>
        </Toolbar>
      </Page.Header>
      <Page.Body>
        <MomentDetailSidebar
          sessionId={sessionId}
          agentId={agentId}
          momentId={detailMomentId}
          fetchEventStreamDetail={fetchEventStreamDetail}
          fetchSession={fetchSession}
          forkEventStream={forkEventStream}
          onHide={() => setDetailMomentId(null)}
        />
        <div ref={startOfStream} />

        {isPending && <Spinner />}
        {eventStream &&
          (!eventStream?.moments || eventStream.moments.length === 0) && (
            <div>No moments found for session</div>
          )}
        <table style={{ width: "100%" }}>
          <tbody>
            {eventStream?.moments.map((moment, index, moments) => {
              return (
                <DisplayMoment
                  key={moment.id}
                  moment={moment}
                  prevMoment={moments[index - 1]}
                  highlightedFnCallId={highlightedFnCallId}
                  onHighlightFnCallId={setHighlightedFnCallId}
                  onClick={handleClickMoment}
                  displaySettings={displaySettings}
                />
              );
            })}
          </tbody>
        </table>

        <div ref={endOfStream} />
      </Page.Body>
    </Page>
  );
}

export default EventStream;
