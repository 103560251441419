import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { ColorAA } from "./constants";

const styleOverrides = {
  variables: {
    light: {
      diffViewerBackground: ColorAA.lightBeige,
      emptyLineBackground: ColorAA.lightBeige,
    },
  },
};

const DiffView = ({
  oldValue,
  newValue,
  showDiffOnly = false,
}: {
  oldValue: string;
  newValue: string;
  showDiffOnly?: boolean;
}) => {
  return oldValue === "" ? (
    newValue
  ) : (
    <ReactDiffViewer
      styles={styleOverrides}
      oldValue={oldValue || ""}
      newValue={newValue || ""}
      hideLineNumbers={true}
      splitView={false}
      showDiffOnly={showDiffOnly}
      compareMethod={DiffMethod.LINES}
    />
  );
};

export default DiffView;
