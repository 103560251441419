interface IconButtonProps {
  className?: string;
  disabled?: boolean;
  onClick?: () => unknown;
}

const IconButton = ({ className, disabled, onClick }: IconButtonProps) => {
  return (
    <button
      disabled={disabled}
      style={{ background: "none", border: "none" }}
      onClick={onClick}
      className={className}
    />
  );
};

export default IconButton;
