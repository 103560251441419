import { Alert, Badge, Table } from "react-bootstrap";
import { CommonAPIRequestSummary } from "../types";
import { FC } from "react";

export enum APIRequestTableColumn {
  CREATED,
  ORGANIZATION,
  PROJECT,
  API_KEY,
  METHOD,
  PATH,
  STATUS_CODE,
}

export const StatusBadge = ({ statusCode }: { statusCode: number }) => {
  const isSuccess = statusCode >= 200 && statusCode < 300;

  const [bg, text] = isSuccess ? ["secondary", undefined] : ["warning", "dark"];

  return (
    <Badge bg={bg} text={text}>
      {statusCode}
    </Badge>
  );
};

const APIRequestRow = ({
  apiRequest,
  columns,
  LinkComponent,
}: {
  apiRequest: CommonAPIRequestSummary;
  columns: Array<APIRequestTableColumn>;
  LinkComponent: FC<{ apiRequest: CommonAPIRequestSummary }>;
}) => {
  return (
    <tr>
      {columns.map((column) => (
        <td key={column}>
          {column === APIRequestTableColumn.CREATED && (
            <LinkComponent apiRequest={apiRequest} />
          )}
          {column === APIRequestTableColumn.ORGANIZATION &&
            apiRequest.organization}
          {column === APIRequestTableColumn.PROJECT && apiRequest.project_name}
          {column === APIRequestTableColumn.API_KEY && apiRequest.api_key_name}
          {column === APIRequestTableColumn.METHOD && (
            <code>{apiRequest.req_method}</code>
          )}
          {column === APIRequestTableColumn.PATH && (
            <code>{apiRequest.req_path}</code>
          )}
          {column === APIRequestTableColumn.STATUS_CODE && (
            <StatusBadge statusCode={apiRequest.res_status_code} />
          )}
        </td>
      ))}
    </tr>
  );
};

const getColumnLabel = (column: APIRequestTableColumn): string => {
  switch (column) {
    case APIRequestTableColumn.CREATED:
      return "Time";
    case APIRequestTableColumn.ORGANIZATION:
      return "Organization";
    case APIRequestTableColumn.API_KEY:
      return "API key";
    case APIRequestTableColumn.PROJECT:
      return "Project";
    case APIRequestTableColumn.METHOD:
      return "Method";
    case APIRequestTableColumn.PATH:
      return "Path";
    case APIRequestTableColumn.STATUS_CODE:
      return "Response";
    default:
      return "";
  }
};

const APIRequestsTable = ({
  apiRequests,
  columns,
  LinkComponent,
}: {
  apiRequests: Array<CommonAPIRequestSummary>;
  columns: Array<APIRequestTableColumn>;
  LinkComponent: FC<{ apiRequest: CommonAPIRequestSummary }>;
}) => {
  if (apiRequests.length === 0) {
    return <Alert className="mt-3">No matching API requests.</Alert>;
  }

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column}>{getColumnLabel(column)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {apiRequests.map((r) => {
          return (
            <APIRequestRow
              key={r.id}
              apiRequest={r}
              columns={columns}
              LinkComponent={LinkComponent}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default APIRequestsTable;
