import { useCallback, useEffect, useState } from "react";
import { Alert, Form, Offcanvas, Spinner } from "react-bootstrap";

import {
  FixedToolbarItem,
  Toolbar,
  TooltipProvider,
} from "@bagel-web/components";
import { CommonMomentDetail, CommonSessionDetails } from "../types/Session";
import MomentDetailView from "./MomentDetailView";
import MomentDetailPlayground from "./MomentDetailPlayground";
import { BagelForkEventStreamCallback } from "../types";

const offcanvasStyle = {
  width: "1000px",
  maxWidth: "50%",
};

const offcanvasHeaderStyle = {
  paddingLeft: "20px",
};

const offcanvasTitleStyle = {
  width: "100%",
};

const offcanvasBodyStyle = {
  padding: "0px 20px",
};

const toggleStyle = {
  fontSize: "16px",
  fontWeight: "normal",
  marginRight: "20px",
};

const playgroundInfoMessage =
  "Tinker with this moment. The original data will not be affected.";
const playgroundDisabledMessage =
  "Playground is unavailable for an agent's first moment in a session.";

const MomentDetailSidebar = ({
  sessionId,
  agentId,
  momentId,
  fetchEventStreamDetail,
  fetchSession,
  forkEventStream,
  onHide,
}: {
  sessionId: string;
  agentId: string;
  momentId: number | null;
  fetchEventStreamDetail: (
    sessionId: string,
    agentId: string,
    momentId: number
  ) => Promise<CommonMomentDetail>;
  fetchSession: (sessionId: string) => Promise<CommonSessionDetails>;
  forkEventStream: BagelForkEventStreamCallback;
  onHide: () => void;
}) => {
  const [momentDetail, setMomentDetail] = useState<CommonMomentDetail | null>(
    null
  );
  const [sessionDetail, setSessionDetail] =
    useState<CommonSessionDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [playgroundEnabled, setPlaygroundEnabled] = useState<boolean>(false);

  useEffect(() => {
    setError(null);
    setPlaygroundEnabled(false);

    const fetchMoment = async () => {
      if (momentId !== null) {
        setMomentDetail(null);
        setMomentDetail(
          await fetchEventStreamDetail(sessionId, agentId, momentId)
        );
      }
    };

    const fetchSessionDetails = async () => {
      if (sessionDetail?.id !== sessionId) {
        setSessionDetail(await fetchSession(sessionId));
      }
    };

    const initialize = async () => {
      try {
        Promise.all([fetchMoment(), fetchSessionDetails()]);
      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("Error fetching moment or session details");
      }
    };

    initialize();
  }, [momentId, sessionId]);

  const handleHideDrawer = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleDrawerExited = useCallback(() => {
    setMomentDetail(null);
    setError(null);
    setPlaygroundEnabled(false);
  }, [setMomentDetail, setError]);

  const playgroundDisabled = momentDetail?.id === 1;

  return (
    <Offcanvas
      style={offcanvasStyle}
      show={momentId !== null}
      onHide={handleHideDrawer}
      onExited={handleDrawerExited}
      backdrop={false}
      placement="end"
    >
      <Offcanvas.Header style={offcanvasHeaderStyle} closeButton>
        <Offcanvas.Title style={offcanvasTitleStyle}>
          <Toolbar>
            <FixedToolbarItem>
              {`Moment ${momentId || momentDetail?.id}`}
            </FixedToolbarItem>
            {momentDetail && sessionDetail && (
              <FixedToolbarItem>
                <TooltipProvider
                  placement="bottom"
                  tooltip={
                    playgroundDisabled
                      ? playgroundDisabledMessage
                      : playgroundInfoMessage
                  }
                >
                  <span>
                    <Form.Check
                      style={toggleStyle}
                      type="switch"
                      label="Playground"
                      disabled={playgroundDisabled}
                      checked={playgroundEnabled}
                      onChange={() => setPlaygroundEnabled(!playgroundEnabled)}
                    ></Form.Check>
                  </span>
                </TooltipProvider>
              </FixedToolbarItem>
            )}
          </Toolbar>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={offcanvasBodyStyle}>
        {(momentDetail === null || sessionDetail == null) && error === null && (
          <Spinner />
        )}
        {error && <Alert variant="warning">{error}</Alert>}
        {momentDetail &&
          sessionDetail &&
          (!playgroundEnabled ? (
            <MomentDetailView
              key={momentDetail.id}
              momentDetail={momentDetail}
            />
          ) : (
            <MomentDetailPlayground
              sessionDetail={sessionDetail}
              momentDetail={momentDetail}
              forkEventStream={forkEventStream}
            />
          ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MomentDetailSidebar;
