import styled from "styled-components";
import {
  Button,
  Form,
  FormGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { GridForm, GridFormItemWrapper } from "@bagel-web/components";
import { EventStreamSettings } from "../types";

const StyledPopover = styled(Popover)`
  max-width: fit-content;
`;

const EventStreamSettingsForm = ({
  settings,
  onSettingsChanged,
}: {
  settings: EventStreamSettings;
  onSettingsChanged: (settings: EventStreamSettings) => void;
}) => {
  return (
    <GridForm $numCols={1}>
      <FormGroup>
        <h6>Facts</h6>
        <GridFormItemWrapper>
          <Form.Check
            type="switch"
            checked={settings.showFactDiffs}
            onChange={(e) => {
              onSettingsChanged({
                ...settings,
                showFactDiffs: e.target.checked,
              });
            }}
            label="Show fact diffs"
          />
        </GridFormItemWrapper>
        <GridFormItemWrapper>
          <Form.Check
            type="switch"
            disabled={!settings.showFactDiffs}
            checked={settings.hideUnchagedFactLines}
            onChange={(e) => {
              onSettingsChanged({
                ...settings,
                hideUnchagedFactLines: e.target.checked,
              });
            }}
            label="Hide unchanged lines in diff"
          />
        </GridFormItemWrapper>
        <GridFormItemWrapper>
          <Form.Check
            type="switch"
            checked={settings.hideUnchangedFacts}
            onChange={(e) => {
              onSettingsChanged({
                ...settings,
                hideUnchangedFacts: e.target.checked,
              });
            }}
            label="Hide unchanged facts"
          />
        </GridFormItemWrapper>
      </FormGroup>
    </GridForm>
  );
};

const EventStreamSettingsButton = ({
  settings,
  onSettingsChanged,
}: {
  settings: EventStreamSettings;
  onSettingsChanged: (settings: EventStreamSettings) => void;
}) => {
  const popover = (
    <StyledPopover>
      <Popover.Body>
        <EventStreamSettingsForm
          settings={settings}
          onSettingsChanged={onSettingsChanged}
        />
      </Popover.Body>
    </StyledPopover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="left"
      overlay={popover}
    >
      <Button className="bi-gear-fill" variant="secondary" />
    </OverlayTrigger>
  );
};

export default EventStreamSettingsButton;
