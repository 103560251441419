import {
  CredentialResponse,
  GoogleOAuthProvider,
  GoogleLogin,
} from "@react-oauth/google";
import { Alert, Container } from "react-bootstrap";

import { CurrentUser } from "api/types";
import { postAPI } from "api";

import type { components } from "api/openapi";
import { useState } from "react";

type CredentialSubmission = components["schemas"]["CredentialSubmission"];

function submitCredentials(
  credentials: CredentialSubmission
): Promise<CurrentUser> {
  return postAPI("/api/auth/credentials", credentials);
}

function AuthButton({
  onSuccess,
  onPending,
  onError,
}: {
  onSuccess: (whoami: CurrentUser) => void;
  onPending: () => void;
  onError: (message: string) => void;
}) {
  const handleSuccess = (response: CredentialResponse) => {
    onPending();

    const credential = response.credential!;

    submitCredentials({ credential })
      .then((whoami: CurrentUser) => onSuccess(whoami))
      .catch((err) => onError(err.message));
  };

  const handleError = () => {
    onError("Google login failed.");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleError} />;
}

function SignInPage({
  clientId,
  onSuccess,
}: {
  clientId: string;
  onSuccess: (whoami: CurrentUser) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Container>
        <h2>Admin Sign In</h2>

        {errorMessage && (
          <Alert variant="warning">Failed to sign in: {errorMessage}</Alert>
        )}

        <AuthButton
          onSuccess={onSuccess}
          onPending={() => setErrorMessage(null)}
          onError={(message) => setErrorMessage(message)}
        />
      </Container>
    </GoogleOAuthProvider>
  );
}

export default SignInPage;
