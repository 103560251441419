import {
  Limits,
  Usage,
  CommonMomentDetail,
  Page,
  FnDesc,
  BagelGameEventMessage,
} from "@bagel-web/common";

import type {
  ApiKey,
  ChalkLog,
  FullChalkLog,
  FnInfo,
  Model,
  Organization,
  OrganizationSummary,
  Project,
} from "./types";
import { APIRequestSummary } from "types/apiRequest";
import { ChalkLogsResponse } from "types/chalkLog";

type ErrorDetails = {
  type: string;
  message: string;
  trace: string;
};

export class APIError extends Error {
  error: ErrorDetails;

  constructor({ error }: { error: ErrorDetails }) {
    super(error.message);
    this.name = "APIError";
    this.error = error;
  }
}

function buildQueryParams(
  obj: Record<string, string | number | boolean | null | undefined>
) {
  return Object.keys(obj)
    .filter((k) => !!obj[k])
    .map((k) => {
      const v = obj[k];
      const encodedValue =
        v !== null && v !== undefined ? encodeURIComponent(v) : "";
      return `${encodeURIComponent(k)}=${encodedValue}`;
    })
    .join("&");
}

export function buildQueryString(
  uri: string,
  obj: Record<string, string | number | boolean | null | undefined>
) {
  const queryString = buildQueryParams(obj);
  return queryString && queryString !== "" ? `${uri}?${queryString}` : uri;
}

async function parseError(res: Response): Promise<Error> {
  try {
    const json = await res.json();

    if ("error" in json) {
      return new APIError(json);
    }
  } catch (e) {
    // fall back to a generic error.
  }

  return new Error(`Unexpected response status ${res.status}`);
}

export async function getAPI<T>(
  url: string,
  init?: { signal?: AbortSignal | null }
): Promise<T> {
  const res = await fetch(url, init);

  if (res.status === 200) return res.json();
  else throw await parseError(res);
}

export async function postAPI<T>(
  url: string,
  body: object,
  options?: {
    headers?: object;
    init?: { signal?: AbortSignal | null };
  }
): Promise<T> {
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json", ...options?.headers },
    body: JSON.stringify(body),
    ...options?.init,
  });

  if (res.status === 200) return res.json();
  else throw await parseError(res);
}

export async function patchAPI<T>(
  url: string,
  body: object,
  init?: { signal?: AbortSignal | null }
): Promise<T> {
  return fetch(url, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    ...init,
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function fetchChalkLog(
  sessionId: string,
  chalkLogId: string
): Promise<FullChalkLog> {
  const url = `/api/chalk-logs/${sessionId}/${chalkLogId}`;
  return getAPI(url);
}

export async function fetchFirstChalkLog(
  sessionId: string
): Promise<ChalkLog | undefined> {
  const url = `/api/chalk-logs`;
  const response = await getAPI<ChalkLogsResponse>(
    buildQueryString(url, {
      session_key: sessionId,
      items_per_page: 1,
      sort_key: "created",
    })
  );
  return response?.items?.[0];
}

export async function fetchMostRecentChalkLog(
  sessionId: string
): Promise<ChalkLog> {
  const url = `/api/sessions/${sessionId}/most-recent-moment`;
  return getAPI(url);
}

export async function fetchModels(): Promise<{ [key: string]: Array<Model> }> {
  return getAPI("/api/models");
}

export async function fetchOrganizations(): Promise<Page<OrganizationSummary>> {
  return getAPI("/api/organizations");
}

export async function fetchOrganization(
  shortName: string
): Promise<Organization> {
  const url = `/api/organizations/${shortName}`;
  return getAPI(url);
}

export async function fetchOrganizationLimits(
  shortName: string
): Promise<Limits> {
  const url = `/api/organizations/${shortName}/limits`;
  return getAPI(url);
}

export async function fetchOrganizationUsage(
  shortName: string
): Promise<Usage[]> {
  const url = `/api/organizations/${shortName}/usage-counts`;
  return getAPI(url);
}

export async function fetchOrganizationUsageBetween(
  shortName: string,
  startDate: string,
  endDate: string
): Promise<Usage[]> {
  return getAPI(
    buildQueryString(`/api/organizations/${shortName}/usage-counts`, {
      between: `daily-${startDate},daily-${endDate}`,
    })
  );
}

export async function patchOrganizationLimits(
  shortName: string,
  limits: Limits
): Promise<Usage[]> {
  const url = `/api/organizations/${shortName}/limits`;
  return await patchAPI(url, limits);
}

export type APIRequestSearchParams = {
  organization?: string | null;
  fromDateTime?: number | null;
  toDateTime?: number | null;
};

export async function fetchOrganizationAPIKeys({
  shortName,
  startsAfter = null,
  notes = null,
}: {
  shortName: string;
  startsAfter: string | null;
  notes: string | null;
}): Promise<Page<ApiKey>> {
  return getAPI(
    buildQueryString(`/api/api-keys/${shortName}`, {
      notes: notes || undefined,
      starting_after: startsAfter || undefined,
    })
  );
}

export async function fetchAPIRequests({
  startsAfter = null,
  onlyFailed = false,
  organization,
  projectId,
  agentId,
  sessionId,
  fromDateTime,
  toDateTime,
}: {
  startsAfter: string | null;
  onlyFailed: boolean;
  projectId?: string;
  organization?: string;
  agentId?: string;
  sessionId?: string;
  fromDateTime?: number;
  toDateTime?: number;
}): Promise<Page<APIRequestSummary>> {
  const url = buildQueryString("/api/api-requests", {
    starting_after: startsAfter,
    only_failed: onlyFailed,
    organization: organization,
    project_id: projectId,
    agent_id: agentId,
    session_id: sessionId,
    from_date_time: fromDateTime,
    to_date_time: toDateTime,
  });

  return getAPI(url);
}

export async function fetchProjects(): Promise<Page<Project>> {
  return getAPI("/api/projects");
}

export function fetchEventStreamDetail(
  sessionId: string,
  agentId: string,
  momentId: number
): Promise<CommonMomentDetail> {
  return getAPI(
    `/api/sessions/${sessionId}/agent/${agentId}/event-stream/${momentId}`
  );
}

export function forkMoment({
  apiKey,
  sessionId,
  agentId,
  momentId,
  projectId,
  metadata,
  functions,
  newHistory,
  cue,
}: {
  apiKey: string;
  sessionId: string;
  agentId: string;
  momentId: number;
  projectId?: string;
  metadata?: Record<string, string>;
  functions: FnDesc[];
  newHistory: BagelGameEventMessage[];
  cue?: string;
}) {
  return postAPI<FnInfo>(
    `/api/sessions/${sessionId}/agent/${agentId}/event-stream/${momentId}/fork`,
    {
      api_key: apiKey,
      project_id: projectId,
      metadata,
      new_history: newHistory,
      functions,
      cue,
    }
  );
}
